export const roomNoFormatter = (roomNo: string) => {
  return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
};

export const isoStringFormatter = (date: string, time: number) => {
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  const hour = time.toString().padStart(2, "0");

  // 날짜와 시간을 결합하여 ISO 8601 형식으로 변환
  return `${year}-${month}-${day}T${hour}:00:00Z`;
};
