/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import MainLayout from "../components/layouts/MainLayout";
import InitialBooking from "../components/views/BookingList";
import CheckedInBooking from "../components/views/CheckedInBooking";
import ModalError from "../components/ModalError";
import Spinner from "../components/Spinner";
import { bookingListAction } from "../stores/actions";
import * as api from "../api/sanha";
import * as serverApi from "../api/server";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../stores/reducers";
import * as GoogleAnalytics from "../lib/google-analytics";
import { isoStringFormatter } from "../lib/formatter";

interface urlQueryStringParams {
  rsvnNo?: string | null;
  rsvnSeqNo?: string | null;
}

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const InitialBookingContainer = (props: Props) => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const urlQueryStringParams: urlQueryStringParams = {
    rsvnNo: searchParams.get("rsvnNo"),
    rsvnSeqNo: "1",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const { bookingItem, roomInfo } = useSelector(
    (state: RootState) => state.bookingList
  );
  const dispatch = useDispatch();

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const listBooking = async () => {
    try {
      setIsLoading(true);
      const response = await serverApi.listBooking(urlQueryStringParams);

      if (response.code === "3025") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_3025_list_booking",
          label: `${response.code}_${response.message}`,
        });
        frontPage();
      } else if (response.code !== "0000") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_list_booking",
          label: `${response.code}_${response.message}`,
        });

        setModalErrorMessage("예약 조회에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }
      if (response.data) {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_list_booking",
          label: `${response.data.rsvnNo}_${response.data.rsvnStatusCode}`,
        });
        dispatch(bookingListAction.setBookingItem(response.data));

        if (response.data.rsvnStatusCode === "CI") {
          dispatch(bookingListAction.setRoomInfo(response.pms_booking));

          const start_at = isoStringFormatter(
            response.data.arrvDate,
            response.data.checkin_hour
          );
          const end_at = isoStringFormatter(
            response.data.deptDate,
            response.data.checkout_hour
          );
          const qrResponse = await serverApi.getQRcode({
            start_at,
            end_at,
          });
          setQrCode(qrResponse.qr_data);
        }
      } else {
        dispatch(bookingListAction.setBookingItem({}));
        dispatch(bookingListAction.setRoomInfo({}));
      }
    } catch (error: any) {
      dispatch(bookingListAction.setBookingItem({}));
      dispatch(bookingListAction.setRoomInfo({}));
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const checkOut = async () => {
    try {
      setIsLoading(true);
      const checkOutAmountResponse = await api.confirmCheckOutAmount({
        folioNo: bookingItem.folioNo || roomInfo.folioNo,
      });
      if (
        checkOutAmountResponse.code !== "0000" &&
        checkOutAmountResponse.code !== "3024"
      ) {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_confirm_checkout_amount",
          label: `${checkOutAmountResponse.code}_${checkOutAmountResponse.message}`,
        });

        setModalErrorMessage("퇴실 잔액 조회에 실패 하였습니다.");
        throw new Error(
          `${checkOutAmountResponse.code}, ${checkOutAmountResponse.message}`
        );
      }
      if (
        checkOutAmountResponse.data.balanceExist === "Y" &&
        checkOutAmountResponse.data.balanceAmount > 0
      ) {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_confirm_checkout_amount",
          label: `${checkOutAmountResponse.code}_${checkOutAmountResponse.data.balanceExist}_${checkOutAmountResponse.data.balanceAmount}`,
        });

        setModalErrorMessage(
          "결제 금액이 남아 있어, 체크아웃에 실패 하였습니다."
        );
        throw new Error("프론트로 퇴실 처리 문의 바랍니다.");
      } else {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_confirm_checkout_amount",
        });
        const checkOutResponse = await api.checkOut({
          folioNo: bookingItem.folioNo || roomInfo.folioNo,
          earlyCheckoutYN: "Y",
        });
        if (checkOutResponse.code !== "0000") {
          GoogleAnalytics.customEvent({
            category: "api_response",
            action: "api_response_fail_checkout_request",
            label: `${checkOutResponse.code}_${checkOutResponse.message}`,
          });
          setModalErrorMessage("체크아웃에 실패 하였습니다.");
          if (checkOutResponse.code === "3011") {
            throw new Error(
              "퇴실일자를 확인하시기 바랍니다. 체크아웃은 당일만 가능합니다."
            );
          } else {
            throw new Error(
              `${checkOutResponse.code}, ${checkOutResponse.message}`
            );
          }
        }

        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_checkout_request",
        });
        navigation("/checkout/payment/success");
      }
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const frontPage = () => {
    navigation("/frontInfo");
  };

  useEffect(() => {
    //if (!_.isEmpty(urlQueryStringParams)) dispatch(bookingListAction.setBookingQueryStringParams(urlQueryStringParams));
    listBooking();
  }, []);

  useEffect(() => {
    if (bookingItem.rsvnNo && bookingItem.rsvnStatusCode === "RR") {
      GoogleAnalytics.customEvent({
        category: "page_view",
        action: "page_view_booking_info",
        label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${bookingItem.guestName}`,
      });
    } else if (bookingItem.rsvnNo && bookingItem.rsvnStatusCode !== "RR") {
      GoogleAnalytics.customEvent({
        category: "page_view",
        action: "page_view_stay_info",
        label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${
          roomInfo.inhsGestName || bookingItem.guestName
        }`,
      });
    }
  }, []);

  return (
    <div {...props}>
      {!_.isEmpty(bookingItem) && bookingItem.rsvnStatusCode === "CI" ? ( // 체크인, 투숙 조회 api 완료되면 원복
        <MainLayout
          customStyle={{ position: "relative", margin: 0 }}
          ContentBody={
            <CheckedInBooking
              bookingItem={{ ...bookingItem, ...roomInfo }}
              qrCode={qrCode}
              checkOut={checkOut}
            />
          }
        />
      ) : (
        <MainLayout
          ContentBody={
            <InitialBooking
              bookingItem={bookingItem}
              setModalErrorMessage={setModalErrorMessage}
              setModalErrorSubMessage={setModalErrorSubMessage}
              openModalError={openModalError}
            />
          }
        />
      )}
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default InitialBookingContainer;
