import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MainLayout from "../components/layouts/MainLayout";
import PaymentLoading from "../components/views/PaymentLoading";
import * as GoogleAnalytics from "../lib/google-analytics";
import { useNavigate, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { bookingListAction } from "../stores/actions";
import _ from "lodash";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckInPaymentResponseContainer = (props: Props) => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const urlQueryStringParams: any = queryString.parse(searchParams.toString());
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(urlQueryStringParams)) {
      dispatch(
        bookingListAction.setBookingQueryStringParams(urlQueryStringParams)
      );
    }
    navigation("/room");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: "page_view",
      action: "page_view_checkin_payment_result",
      label: urlQueryStringParams
        ? `${urlQueryStringParams.ResultCode}_${urlQueryStringParams.ResultMsg}}`
        : "null",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...props}>
      <MainLayout ContentBody={<PaymentLoading />} />
    </div>
  );
};

export default CheckInPaymentResponseContainer;
