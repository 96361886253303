import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import * as GoogleAnalytics from "../lib/google-analytics";
import * as sanhaApi from "../api/sanha";
import { RootState } from "../stores/reducers";

import MainLayout from "../components/layouts/MainLayout";
import Payment from "../components/views/Payment";
import ModalError from "../components/ModalError";
import Spinner from "../components/Spinner";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckOutPaymentContainer = (props: Props) => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const { bookingItem } = useSelector((state: RootState) => state.bookingList);
  const [totalAmount, setTotalAmount] = useState(
    Number(searchParams.get("totalAmount ")) || 0
  );
  const [isLoading, setIsLoading] = useState(false);

  const openModalError = () => {
    setIsOpenModalError(true);
    setIsError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
  };

  const confirmCheckOutPayment = async () => {
    try {
      setIsLoading(true);
      //미결제 금액 확인
      const { code, message, data } = await sanhaApi.confirmCheckOutAmount({
        folioNo: bookingItem.folioNo,
      });
      if (code !== "0000") {
        setModalErrorMessage("퇴실 미결제 금액 확인에 실패 하였습니다.");
        throw new Error(`${code}, ${message}`);
      } else if (!data.balanceAmount) {
        navigation(`/?rsvnNo=${bookingItem.rsvnNo}`); //첫화면으로 원복
      } else {
        setTotalAmount(data.balanceAmount);
      }
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    confirmCheckOutPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: "page_view",
      action: "page_view_checkout_payment",
      label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${totalAmount}`,
    });
  }, [bookingItem.rsvnNo, bookingItem.rsvnStatusCode, totalAmount]);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={
          <Payment
            bookingItem={bookingItem}
            amount={totalAmount}
            isError={isError}
            errorMessage={modalErrorSubMessage}
          />
        }
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default CheckOutPaymentContainer;
