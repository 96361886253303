import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import * as GoogleAnalytics from "../lib/google-analytics";
import { bookingListAction } from "../stores/actions";
import { RootState } from "../stores/reducers";

import MainLayout from "../components/layouts/MainLayout";
import CheckIn from "../components/views/CheckIn";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckInContainer = (props: Props) => {
  const navigation = useNavigate();
  const { bookingItem } = useSelector((state: RootState) => state.bookingList);
  const isTestMode = process.env.REACT_APP_ENV === "development";

  const dispatch = useDispatch();
  const { Kakao } = window as any;

  const getUserInfoWithKakao = useCallback(async () => {
    try {
      Kakao.API.request({
        url: "/v2/user/me",
        success: function (response: any) {
          const parsedPhoneNumber = `0${response.kakao_account.phone_number
            .split(" ")[1]
            .replace(/-/gi, "")}`;
          dispatch(
            bookingListAction.setUserInfo({
              phoneNumber: parsedPhoneNumber,
              email: response.kakao_account.email,
              hotel_id: "e350fed665e0278d2f3e27f2dd1c0076fa26f1d4",
              account_email: response?.kakao_account?.email,
              name: response?.kakao_account?.name,
              gender: response?.kakao_account?.gender,
              age_range: response?.kakao_account?.age_range,
              birthday: response?.kakao_account?.birthday,
              birthyear: response?.kakao_account?.birthyear,
              phone_number: response?.kakao_account?.phone_number,
            })
          );
          navigation("/checkin/payment");
        },
        fail: function (error: any) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithKakao = useCallback(() => {
    if (isTestMode) {
      navigation("/checkin/payment");
      return;
    }
    try {
      Kakao.Auth.login({
        success: function (response: any) {
          getUserInfoWithKakao();
        },
        fail: function (error: any) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingItem.rsvnStatusCode !== "RR")
      navigation(`/?rsvnNo=${bookingItem.rsvnNo}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingItem.rsvnNo, bookingItem.rsvnStatusCode]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: "page_view",
      action: "page_view_kakao_auth",
    });
  }, []);

  return (
    <div {...props}>
      <MainLayout ContentBody={<CheckIn loginWithKakao={loginWithKakao} />} />
    </div>
  );
};

export default CheckInContainer;
