import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import * as sanhaApi from "../api/sanha";
import * as GoogleAnalytics from "../lib/google-analytics";
import { RootState } from "../stores/reducers";

import MainLayout from "../components/layouts/MainLayout";
import Payment from "../components/views/Payment";
import ModalError from "../components/ModalError";
import Spinner from "../components/Spinner";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckInPaymentContainer = (props: Props) => {
  const navigation = useNavigate();
  const { bookingItem, paymentInfo, queryStringParams } = useSelector(
    (state: RootState) => state.bookingList
  );

  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const [totalAmount, setTotalAmount] = useState(bookingItem.roomFee);
  const [isLoading, setIsLoading] = useState(false);

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
    setIsError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const confirmCheckInPayment = async () => {
    try {
      setIsLoading(true);
      //해당 호텔이 결제 로직 있는지 체크
      const { code, message, data: configData } = await sanhaApi.config();
      if (code !== "0000") {
        setModalErrorMessage("예약 결제 여부 확인에 실패 하였습니다.");
        throw new Error(`${code}, ${message}`);

        // pg 연동 여부 === "N" 이면 체크인 처리(객실 선택 있으면 객실 선택으로)
      } else if (configData.pgPaymentYn === "N") {
        navigation("/room");
        return;

        // 체크인 결제 여부 === "N" 이면 체크인 처리(객실 선택 있으면 객실 선택으로)
      } else if (configData.depositUseYn === "N") {
        navigation("/room");
        return;

        // 결제 로직이 있다면
      } else {
        // 밸런스(총 금액) 체크
        const { code, message, data } = await sanhaApi.confirmCheckInAmount({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: "1",
        });

        if (code !== "0000") {
          setModalErrorMessage("예약 결제 금액 확인에 실패 하였습니다.");
          throw new Error(`${code}, ${message}`);

          // 총금액이 0 이면 (결제 할 금액이 없다면) /room 으로 이동
        } else if (data.totalAmount === 0) {
          navigation("/room");
          return;

          // 결제 할 금액이 있다면 금액 저장
        } else {
          setTotalAmount(data.totalAmount);
        }
      }
      // 해당 예약이 결제해야 하는지 체크
      const { data } = await sanhaApi.confirmCheckInPayment({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
      });

      if (data.payYn === "Y") navigation("/room");
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      paymentInfo &&
      paymentInfo.isPaid &&
      paymentInfo.rsvnNo === bookingItem.rsvnNo
    )
      navigation("/room"); //결제 이력이 있을 경우 바로 객실 선택으로
    else if (
      queryStringParams &&
      queryStringParams.responseCode === "0000" &&
      queryStringParams.shopOrderNo === bookingItem.rsvnNo
    ) {
      navigation("/room");
    } else {
      confirmCheckInPayment(); //결제 금액 체크
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: "page_view",
      action: "page_view_checkin_payment",
      label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${bookingItem.guestName}`,
    });
  }, [bookingItem.guestName, bookingItem.rsvnNo, bookingItem.rsvnStatusCode]);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={
          <Payment
            bookingItem={bookingItem}
            amount={totalAmount}
            isError={isError}
            errorMessage={modalErrorSubMessage}
          />
        }
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default CheckInPaymentContainer;
