import { Fragment } from "react";

interface PaymentProps {
  bookingItem: any;
  amount: number;
  isError: boolean;
  errorMessage: string;
}

const Payment = ({
  bookingItem,
  amount,
  isError,
  errorMessage,
}: PaymentProps) => {
  const { rsvnNo, guestName, mobileNo, email, roomTypeCode, roomTypeDesc } =
    bookingItem;

  const registerTransaction = () => {
    const approvalForm = document.getElementById(
      "approvalForm"
    ) as HTMLFormElement;

    approvalForm.action = "https://mobile.inicis.com/smart/payment/";
    approvalForm.target = "_self";
    approvalForm.submit();
  };

  const totalAmountFormatter = (totalAmount: number) => {
    return totalAmount
      ? totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : totalAmount;
  };

  return (
    <Fragment>
      <span className="check-in-payment-title">결제하기</span>
      <div className="product-name-container">
        <span>상품명</span>
        <span className="product-name-value">
          [{roomTypeCode}] {roomTypeDesc}
        </span>
      </div>
      <div className="total-amount-container">
        <span>총 금액</span>
        <span className="total-amount-value">
          {`${totalAmountFormatter(amount)}원`}
        </span>
      </div>
      {isError ? (
        <div className="payment-error-message">
          <span>에러가 발생했습니다. 결제 진행을 할 수 없습니다.</span>
          <div className="error-message">({errorMessage})</div>
        </div>
      ) : (
        <button
          className="check-in-payment-button"
          onClick={registerTransaction}
        >
          결제하기
        </button>
      )}

      <form
        id="approvalForm"
        name="approvalForm"
        method="post"
        acceptCharset="euc-kr"
      >
        <input type="hidden" name="P_INI_PAYMENT" value="CARD" />
        <input
          type="hidden"
          name="P_MID"
          value={process.env.REACT_APP_KG_MALL_ID}
        />
        <input type="hidden" name="P_OID" value={rsvnNo} />
        <input type="hidden" name="P_AMT" value={amount} />
        <input type="hidden" name="P_GOODS" value={roomTypeDesc} />
        <input type="hidden" name="P_UNAME" value={guestName} />
        <input type="hidden" name="P_MOBILE" value={mobileNo} />
        <input type="hidden" name="P_EMAIL" value={email} />
        <input type="hidden" name="P_CHARSET" value="utf8" />
        <input
          type="hidden"
          name="P_NEXT_URL"
          value={process.env.REACT_APP_KG_CALLBACK_URL}
        />
        <input
          type="hidden"
          name="P_RESERVED"
          value="below1000=Y&centerCd=Y&useescrow=Y"
        />
      </form>
    </Fragment>
  );
};

export default Payment;
